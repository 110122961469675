import { LinkContainer, LinkIconContainer } from "../styles"

import { Shipments } from "../../../../../../icons"
import { ShippingCostModalTrigger } from "../../ShippingCostModalTrigger"
import { api } from "../../../../../../api"

type ShippingCostProps = {
  canQuote: boolean
  productID: string
  variantID: number
  quantity: number
  customMethods: api.stores.shippings.Get.Custom[]
  freeShipping: api.stores.shippings.Get.FreeShipping
  productPrice: number
  currency: "ARS" | "BRL" | "MXN"
}

export function ShippingCostLink({
  productID,
  variantID,
  quantity,
  customMethods,
  canQuote,
  freeShipping,
  productPrice,
  currency,
}: ShippingCostProps) {
  return (
    <LinkContainer>
      <LinkIconContainer>
        <Shipments />
      </LinkIconContainer>
      <ShippingCostModalTrigger
        linkId="shipping-cost-link"
        triggerTitle={"Conocer costos y formas de envío"}
        productID={productID}
        variantID={variantID}
        quantity={quantity}
        customMethods={customMethods}
        canQuote={canQuote}
        freeShipping={freeShipping}
        productPrice={productPrice}
        currency={currency}
      />
    </LinkContainer>
  )
}
