import { HEADER_HEIGHT, colors, mediaQuery } from "../../../../theme"
import { Trans, t } from "@lingui/macro"
import { useEffect, useRef, useState } from "react"
import { usePaymentMethods, useScreenSize } from "../../../../hooks"

import { AddToCartButton } from "./AddToCartButton"
import { AlertBox } from "../../../../components/AlertBox"
import { FreeShippingSticky } from "./FreeShippingSticky"
import { InformationLinks } from "./InformationLinks"
import { OffersDiscount } from "./OffersDiscount/OffersDiscount"
import { OutStockAlert } from "../../../../components/OutStockAlert"
import { PriceView } from "../../../../components/PriceView"
import { ProductNamePrice } from "./ProductNamePrice"
import { ProductVariants } from "./ProductVariants"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { QuantitySelector } from "../../../../components/QuantitySelector"
import { Result } from "../../../../api/stores/shippings/get/types"
import { api } from "../../../../api"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../utils/PriceUtil"
import { useProductStore } from "../../provider/ProductStoreProvider"
import { useQuantitySelector } from "../../hooks/useQuantitySelector"
import { useStoreState } from "../../../../providers/StoreProvider"

type Props = {
  cartError: boolean
  minPrice: number
  productHasDifferentPrices?: boolean
  productHasStock?: boolean
  productID: string
  handleAddToCart: () => void
  cartIsLoading: boolean
  canQuote: boolean
}

export function NamePriceActions({
  cartError,
  minPrice,
  productHasStock,
  productID,
  handleAddToCart,
  cartIsLoading,
  canQuote,
}: Props) {
  const { product, selectedVariant, quantity } = useProductStore()
  const { variants, title } = product
  const { handleChangeQuantity, handleOnBlur } = useQuantitySelector()
  const [variantHasError, setVariantHasError] = useState(false)
  const [state] = useStoreState()
  const { isMobile } = useScreenSize()
  const variantsPropertiesRef = useRef<HTMLDivElement | null>(null)
  const paymentMethods = usePaymentMethods()
  const { custom } = paymentMethods.info.objects

  const noStockAvailable =
    selectedVariant != null && quantity > selectedVariant.stock

  const addToCartButtonError = variantHasError || cartError

  const productPrice = !selectedVariant ? minPrice : selectedVariant.price

  const notAllowedAddToCartButton = variants.every(
    variant => variant.stock === 0
  )

  const noPaymentMethods = Object.values(paymentMethods.info.objects).every(
    method => method.length === 0
  )

  const price = !selectedVariant ? (
    <PriceView price={toLocalePrice(minPrice, variants[0].currency)} />
  ) : (
    <PriceView
      price={toLocalePrice(selectedVariant.price, selectedVariant.currency)}
    />
  )

  const [shippingData, setShippingData] = useState<Result>()

  const showFreeShippingSticky =
    !!selectedVariant && selectedVariant.freeShipping && !noStockAvailable

  const showOffersDiscount = custom.some(item => item.offersDiscount === true)
  const offersDiscounPayments = custom
    .filter(item => item.offersDiscount === true)
    .sort((a, b) => {
      if (a.discountType === "P" && b.discountType === "P") {
        return (b.discountValue || 0) - (a.discountValue || 0)
      } else if (a.discountType === "A" && b.discountType === "A") {
        return (b.discountValue || 0) - (a.discountValue || 0)
      } else if (a.discountType === "P") {
        return -1
      } else if (b.discountType === "P") {
        return 1
      } else if (a.discountType === "A") {
        return -1
      } else if (b.discountType === "A") {
        return 1
      } else {
        return 0
      }
    })

  useEffect(() => {
    api.stores
      .set()
      .shippings.get()
      .then(response => setShippingData(response))
      .catch(console.error)
  }, [])

  const isDisabledProduct =
    !canQuote &&
    shippingData?.branches.length === 0 &&
    shippingData?.custom.length === 0

  const fromMinPrice = (
    <Trans>
      <FromMessage>Desde</FromMessage> {price}
    </Trans>
  )

  const stickyFromMinPrice = (
    <Trans>
      <StickyFromMessage>Desde</StickyFromMessage> {price}
    </Trans>
  )

  const displayPrice = (type?: string) => {
    if (selectedVariant || productHasStock) return price
    else if (type === "sticky") return stickyFromMinPrice
    else return fromMinPrice
  }

  const errorText = cartError ? (
    <ErrorMessage>
      <Trans>No se pudo agregar al carrito, intente más tarde.</Trans>
    </ErrorMessage>
  ) : null
  return (
    <NamePriceSection>
      <NamePriceContainer>
        {!productHasStock && <OutStockAlert />}
        <ProductNamePrice name={title} price={displayPrice()} />
      </NamePriceContainer>
      {showFreeShippingSticky && (
        <FreeShippingStickyContainer>
          <FreeShippingSticky
            productID={productID}
            variantID={variants[0].id as number}
            quantity={quantity}
            canQuote={canQuote}
            shippingData={shippingData}
            productPrice={productPrice}
            currency={selectedVariant.currency}
          />
        </FreeShippingStickyContainer>
      )}
      {showOffersDiscount && (
        <OffersDiscount paymentMethod={offersDiscounPayments[0]} />
      )}
      {isDisabledProduct && (
        <AlertBoxContainer data-testid="disabled-product">
          <AlertBox
            text={
              <Trans>
                Este producto no está disponible para envío a domicilio o retiro
                en correo cercano. <br /> Contactanos para poder comprarlo.
              </Trans>
            }
            variant="warning"
            size="medium"
          />
        </AlertBoxContainer>
      )}
      <ActionsContainer>
        <VariantsContainer ref={variantsPropertiesRef}>
          <ProductVariants
            isDisabledProduct={isDisabledProduct}
            variantHasError={variantHasError}
            resetVariantHasError={resetVariantHasError}
          />
        </VariantsContainer>
        <QuantitySelectorContainer>
          <QuantitySelector
            quantityInformation={
              notAllowedAddToCartButton ? 0 : selectedVariant?.stock
            }
            onChange={handleChangeQuantity}
            quantitySelected={quantity}
            onBlur={handleOnBlur}
            error={noStockAvailable}
            productHasStock={productHasStock}
            disabled={isDisabledProduct}
          />
          <StockErrorContainer>
            {noStockAvailable && selectedVariant?.stock !== 0 && (
              <ErrorMessage>
                <Trans>
                  Ingresá una cantidad igual o menor al stock disponible.
                </Trans>
              </ErrorMessage>
            )}
          </StockErrorContainer>
        </QuantitySelectorContainer>
        <AddToCartButton
          price={displayPrice("sticky")}
          onClick={addToCartClickHandler}
          tooltipDescription={
            noPaymentMethods
              ? t`No podés agregar productos al carrito en este momento`
              : !productHasStock
              ? t`No hay stock disponible`
              : ""
          }
          tooltipDisabled={!noPaymentMethods && productHasStock}
          isDisabled={
            isDisabledProduct ||
            notAllowedAddToCartButton ||
            cartIsLoading ||
            noPaymentMethods
          }
          error={addToCartButtonError && errorText}
          spinnerSize={"2rem"}
        />
        <ApiErrorContainer>
          {addToCartButtonError && errorText}
        </ApiErrorContainer>
        <InformationLinksContainer>
          <InformationLinks
            productID={productID}
            variantID={variants[0].id as number}
            quantity={quantity}
            canQuote={canQuote}
            shippingData={shippingData}
            productPrice={productPrice}
            currency={selectedVariant?.currency || "ARS"}
          />
        </InformationLinksContainer>
      </ActionsContainer>
    </NamePriceSection>
  )

  function addToCartClickHandler() {
    if (selectedVariant && !variantHasError) {
      handleAddToCart()
    } else {
      setVariantHasError(true)
      if (isMobile && variantsPropertiesRef.current) {
        const { sticky } = state.config.header

        setVariantHasError(true)

        const headerOffset = !sticky ? 0 : HEADER_HEIGHT.MOBILE

        const elementPosition = variantsPropertiesRef.current.getBoundingClientRect()
          .top

        const offsetPosition = elementPosition - headerOffset

        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }

  function resetVariantHasError() {
    setVariantHasError(false)
  }
}

const NamePriceContainer = styled.div({
  padding: "3.2rem 1.6rem 0.8rem",

  [mediaQuery.desktop]: {
    padding: "1.6rem 2rem 1.2rem",
  },
})

const FreeShippingStickyContainer = styled.div({
  padding: "0 1.2rem 0.8rem",

  [mediaQuery.desktop]: {
    padding: "0 2rem 1.2rem",
  },
})

const ActionsContainer = styled.div({
  display: "grid",
  gridTemplateAreas: `"variants"
                      "quantitySelector"
                      "addToCartButton"
                      "error"
                      "informationLinks"`,
  paddingBottom: "2.2rem",

  [mediaQuery.desktop]: {
    gridTemplateAreas: `"variants"
                      "quantitySelector"
                      "informationLinks"
                      "addToCartButton"
                      "error"`,
    paddingBottom: "0rem",
  },
})

const VariantsContainer = styled.div({
  gridArea: "variants",
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: colors.greyLight,
  padding: "0 1.6rem",

  [mediaQuery.desktop]: {
    padding: "0 1.6rem 2.4rem",
  },
})

const QuantitySelectorContainer = styled.div({
  gridArea: "quantitySelector",
  padding: "2.4rem 1.6rem 2.4rem",

  [mediaQuery.desktop]: {
    padding: "0 1.6rem 2.4rem",
  },
})

const StockErrorContainer = styled.div({
  height: "1.6rem",
})

const ApiErrorContainer = styled.div({
  gridArea: "error",
  display: "none",

  [mediaQuery.desktop]: {
    display: "block",
    margin: "0.8rem 1.2rem 0",
    height: "1.6rem",
  },
})

const InformationLinksContainer = styled.div({
  gridArea: "informationLinks",
  padding: "0 1.6rem",

  [mediaQuery.desktop]: {
    padding: "0 1.6rem 2.4rem",
  },
})

const ErrorMessage = styled.p<PropsWithTheme>(props => ({
  margin: 0,
  fontSize: "1.2rem",
  color: colors.redMain,
  fontFamily: "Open Sans, sans-serif",
}))

const StickyFromMessage = styled.p({
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.2rem",
  fontWeight: "normal",
  margin: 0,
})

const FromMessage = styled.p<PropsWithTheme>(props => ({
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.8rem",
  marginRight: "0.8rem",
  fontWeight: "normal",
  marginTop: 0,
  marginBottom: 0,

  [mediaQuery.desktop]: {
    fontFamily: props.theme.typographies.texts,
    fontSize: "1.8rem",
    marginRight: "0.8rem",
  },
}))

const AlertBoxContainer = styled.div({
  padding: "1.2rem",
})

const NamePriceSection = styled.section()
