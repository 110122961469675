import {
  FreeShipping,
  Result,
} from "../../../../../api/stores/shippings/get/types"

import { PaymentMethodsLink } from "../../../../../components/PaymentMethodsLink"
import { ShippingCostLink } from "./ShippingCostLink"
import { StorePickUpLink } from "./StorePickUpLink"
import { mediaQuery } from "../../../../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  productID: string
  variantID: number
  quantity: number
  canQuote: boolean
  shippingData: Result | undefined
  productPrice: number
  currency: "ARS" | "BRL" | "MXN"
}

const defaultFreeShippingValues: FreeShipping = {
  active: false,
  starterAmount: 0,
}

export function InformationLinks({
  productID,
  variantID,
  quantity,
  canQuote,
  shippingData,
  productPrice,
  currency = "ARS",
}: Props) {
  return (
    <Container>
      <PaymentMethodsLink />
      {(canQuote ||
        (shippingData != null && shippingData?.custom.length > 0)) && (
        <ShippingCostLink
          productID={productID}
          variantID={variantID}
          quantity={quantity}
          customMethods={shippingData?.custom || []}
          canQuote={canQuote}
          freeShipping={shippingData?.freeShipping ?? defaultFreeShippingValues}
          productPrice={productPrice}
          currency={currency}
        />
      )}
      {shippingData != null && shippingData?.branches.length > 0 && (
        <StorePickUpLink branches={shippingData?.branches} />
      )}
    </Container>
  )
}

const Container = styled.div({
  display: "grid",
  gridGap: "2rem",

  [mediaQuery.desktop]: {
    gridGap: "1.2rem",
  },
})
